import React, { Fragment } from "react"
import ProductImg from "../images/pocinkovana.jpg"
import "../css/products.css"
import { Link } from "gatsby"

const ServiceCard = props => {
  return (
    <Fragment>
      <div className="column is-full-mobile" data-aos="zoom-in">
        <div className="card product-card services has-text-white">
          <div className="card-image "></div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <Link className="subtitle has-text-white is-3">
                  {props.title}
                </Link>
              </div>
            </div>
            <footer className="card-footer content is-flex">
              <Link
                to={props.to}
                className="button is-link  is-fullwidth is-uppercase"
              >
                POGLEDAJ
              </Link>
            </footer>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ServiceCard
