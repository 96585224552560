import React, { Fragment } from "react"
import ProductImg from "../images/pocinkovana.jpg"
import "../css/products.css"
import { Link } from "gatsby"

const ProductCard = props => {
  return (
    <Fragment>
      <div
        className="column is-one-fifth-desktop is-half-mobile"
        data-aos="zoom-in"
      >
        <div className="card product-card">
          <div className="card-image">
            <figure className="image is-4by3">
              <img src={props.img} alt="Placeholder image" />
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <Link className="subtitle is-6">{props.title}</Link>
              </div>
            </div>
            <footer className="card-footer content is-flex">
              <Link
                to={props.to}
                className="button is-link is-outlined is-fullwidth is-uppercase"
              >
                Pogledaj
              </Link>
            </footer>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default ProductCard
