import React from "react"
import Layout from "../../components/layout"
import ProductCard from "../../components/ProductCard"
import "../../css/products.css"
import AOS from "aos"
import PvcImg from "../../images/pvcojacanja2.jpg"
import KlizneImg from "../../images/klizne1.jpg"
import StraniceImg from "../../images/stranice.jpg"
import NosaciImg from "../../images/nosaci.jpg"
import SavneImg from "../../images/savne.jpg"
import ProfilisaniImg from "../../images/profilisani.jpg"
import PocinkovaniImg from "../../images/pocinkovanilim.jpg"
import HladnovaljaniImg from "../../images/hladnovaljani.jpg"
import ToplovaljaniImg from "../../images/toplovaljani.jpg"
import ToplovaljaniRebrastiImg from "../../images/tvrebrasti.jpg"
import ToplovaljaniKotlovskiImg from "../../images/tvkotlovski.jpg"
import PocinkovaneImg from "../../images/pocinkovane1.jpg"
import SineImg from "../../images/sine1.jpg"
import HopImg from "../../images/hop-profili.jpg"

import ProductsLayout from "../../components/productsLayout"
import { useEffect } from "react"
import ServiceCard from "../../components/ServiceCard"
import Head from "../../components/head"

const IndexPage = () => {
  useEffect(() => {
    // Aos
    AOS.init({
      once: true,
    })
  })
  return (
    <Layout>
      <Head title="Proizvodi" />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">Proizovodi</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          Proizvodi
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div className="products-list">
            <div className="columns is-mobile is-multiline" data-aos="zoom-in">
              <ProductCard
                title="Šavne cevi"
                img={SavneImg}
                to="/proizvodi/šavne-cevi"
              />
              <ProductCard
                title="Toplovaljani limovi"
                img={ToplovaljaniImg}
                to="/proizvodi/toplovaljani-lim"
              />
              <ProductCard
                title="Toplovaljani rebrasti limovi"
                img={ToplovaljaniRebrastiImg}
                to="/proizvodi/toplovaljani-rebrasti-limovi"
              />
              <ProductCard
                title="Toplovaljani kotlovski limovi"
                img={ToplovaljaniKotlovskiImg}
                to="/proizvodi/toplovaljani-kotlovski-limovi"
              />
              <ProductCard
                title="Hladnovaljani limovi"
                img={HladnovaljaniImg}
                to="/proizvodi/hladnovaljani-limovi"
              />
              <ProductCard
                title="Pocinkovani limovi"
                img={PocinkovaniImg}
                to="/proizvodi/pocinkovani-limovi"
              />

              <ProductCard
                to="/proizvodi/profili-nosači"
                title="Profili i nosači"
                img={NosaciImg}
              />
              <ProductCard
                to="/proizvodi/pocinkovane-cevi"
                title="Pocinkovane cevi"
                img={PocinkovaneImg}
              />
              <ProductCard
                to="/proizvodi/klizne-šine"
                title="Klizne šine"
                img={SineImg}
              />
              <ProductCard
                to="/proizvodi/profilisani-limovi"
                title="Profilisani limovi"
                img={ProfilisaniImg}
              />
              <ProductCard
                title="PVC ojačanja"
                img={PvcImg}
                to="/proizvodi/pvc-ojačanja"
              />
              <ProductCard
                title="CD/UD
Profili"
                img={KlizneImg}
                to="/proizvodi/cd-ud-profili"
              />
              <ProductCard
                title="Čelične stranice"
                img={StraniceImg}
                to="/proizvodi/čelične-stranice"
              />
              <ProductCard
                title="HOP Profili"
                img={HopImg}
                to="/proizvodi/hop-profili"
              />
            </div>
          </div>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default IndexPage
